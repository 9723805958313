import './font/stylesheet.css'
import './style.css'
import "./skin.css"

import Alpine from 'alpinejs'
import videojs from 'video.js'

window.Alpine = Alpine;

Alpine.start();

document.addEventListener('alpine:init', () => {
    console.info("init");
})


window.direttaTV = (id) =>{
    const player = videojs(id, {
        controls: true,
        autoplay: false,
        preload: 'auto'
      });
}